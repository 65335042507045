<template>
  <div class="">
    <el-form ref="form"
             :rules="rules"
             label-position="top"
             :model="form">
      <el-form-item label="关联商户号"
                    class="width300"
                    prop="merchant_id">
        <el-select class="width100"
                   v-model="form.merchant_id"
                   filterable
                   remote
                   placeholder="请输入关键词"
                   :disabled="form.id?true:false"
                   :remote-method="remoteMethod"
                   :loading="selectLoading">
          <el-option v-for="item in options"
                     :key="item.id"
                     :label="item.merchant_num"
                     :value="item.id">
            <span style="float: left">{{ item.name }}</span>
            <span style="float: right; color: #8492a6; font-size: 13px">{{ item.merchant_num }}</span>
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="门店名称"
                    class="width300"
                    prop="name">
        <el-input v-model="form.name"
                  placeholder="请输入门店名称"></el-input>
      </el-form-item>
      <el-form-item label="门店头像"
                    ref="headImg"
                    prop="head_img_id">
        <ImgUpload :fileList="storeImg"
                   pixel="用于页面配图，建议使用300*300像素"
                   v-model="form.head_img_id" />
      </el-form-item>
      <el-form-item label="经营类目"
                    prop="category_id">
        <el-select v-model="form.category_id"
                   placeholder="请选择">
          <el-option v-for="item in cates"
                     :key="item.value"
                     :label="item.label"
                     :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="门店地址">
        <el-row>
          <el-col :span="16">
            <Address :address.sync="address"
                     @change="addressChange" />
          </el-col>
        </el-row>
      </el-form-item>
      <el-form-item label="详细地址"
                    class="width300">
        <el-row type="flex"
                class="mt-15">
          <el-input v-model="form.address"
                    class="width300 mr-15"
                    placeholder="请输入详细地址"></el-input>
          <el-button type="primary"
                     @click="$refs.map.initMap({lat:form.latitude,lng:form.longitude})">地图定位</el-button>
        </el-row>
      </el-form-item>
      <el-form-item label="营业时间"
                    class="width300">
        <el-input v-model="form.open_time"
                  placeholder="请输入营业时间"></el-input>
      </el-form-item>
      <el-form-item label="门店服务">
        <el-checkbox-group v-model="form.provide_services">
          <el-checkbox label="免费WIFI"></el-checkbox>
          <el-checkbox label="免费停车"></el-checkbox>
          <el-checkbox label="24小时营业"></el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <el-form-item label="店内照片">
        <ImgUpload :fileList="storePicture"
                   pixel="支持多张图片"
                   multiple
                   v-model="form.picture_ids" />
      </el-form-item>
      <el-form-item label="是否认证">
        <el-switch v-model="form.status"
                   active-value="NORMAL"
                   inactive-value="PENDING">
        </el-switch>
      </el-form-item>
      <el-form-item size="large">
        <el-button type="primary"
                   @click="onSubmit('form')">{{form.id?'立即保存':'立即创建'}}</el-button>
      </el-form-item>
    </el-form>
    <Map @on-click="choseLocation"
         ref="map" />
  </div>
</template>

<script>
import ImgUpload from '@/components/ImgUpload'
import Map from '@/components/Map'
import Address from '@/components/Address'
import { fetchMerchants, setStore, fetchStoreDetail } from '@/api/merchant'

import { fetchCates } from '@/api'
import { getImgId } from '@/utils'
export default {
  name: '',
  data () {
    return {
      cates: [],
      storePicture: [],
      storeImg: [],
      form: {
        name: '', // 门店名称
        category_id: '', // 经营类目id
        head_img_id: '', // 门店头像
        store_id: '', // 门店id
        merchant_id: '', // 商户id
        province: '',
        city: '',
        district: '',
        address: '', // 详细地址
        longitude: '',
        latitude: '',
        open_time: '', // 营业时间
        provide_services: [],
        picture_ids: [], // 具体门店照片
        status: 'PENDING' // 是否认证
      },
      merchantTypeList: ['微信邮付商户', '其他微信支付商户'], // 商户类型列表
      rules: {
        merchant_id: [{ required: true, message: '请输入商户号搜索并选择', trigger: 'change' }],
        name: [
          { required: true, message: '请输入门店名称', trigger: 'blur' }
        ],
        head_img_id: [
          { required: true, message: '请上传门店头像', trigger: 'change' }
        ],
        category_id: [
          { required: true, message: '请选择经营类目', trigger: 'change' }
        ]
      },
      dialogVisible: false,
      options: [],
      selectLoading: false
    }
  },
  components: {
    ImgUpload,
    Address,
    Map
  },
  computed: {
    address () {
      const form = this.form
      return {
        province: form.province,
        city: form.city,
        district: form.district
      }
    }
  },
  methods: {
    async remoteMethod (query) {
      if (query !== '' && query.length > 4) {
        this.selectLoading = true
        const res = await fetchMerchants({
          page: 1,
          size: 5,
          search_type: 'MERCHANT_NUM',
          search_keyword: query
        })
        this.selectLoading = false
        if (res.meta.code === 0) {
          this.options = res.data.merchants
        }
      } else {
        this.options = []
      }
    },
    async fetchCates () {
      const res = await fetchCates()
      if (res.meta.code === 0) {
        this.cates = []
        res.data.categories.map(item => {
          this.cates.push({
            value: item.id,
            label: item.name
          })
        })
      }
    },
    // 地图组件回调
    choseLocation (res) {
      this.form.address = res.address
      this.form.longitude = res.location.lng
      this.form.latitude = res.location.lat
    },
    addressChange (res) {
      this.form.province = res.province
      this.form.city = res.city
      this.form.district = res.district
    },
    async fetchStoreDetail (id) {
      const res = await fetchStoreDetail({
        store_id: id
      })
      if (res.meta.code === 0) {
        const store = res.data.store
        store.store_id = store.id
        store.category_id = store.category[0] && store.category[0].id
        store.head_img_id = getImgId(store.head_img)
        store.head_img && this.$set(this.storeImg, 0, store.head_img)
        this.storePicture = store.picture_img
        store.picture_ids = getImgId(store.picture_img)
        this.form = store
        this.remoteMethod(store.merchant_num)
      }
    },
    onSubmit (formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const res = await setStore(this.form)
          if (res.meta.code === 0) {
            this.$router.push('stores')
          } else {
            this.$message.error(res.meta.msg)
          }
        } else {
          this.$message.error('表单信息填写有误, 请检查')
        }
      })
    }
  },
  mounted () {
    this.fetchCates()
    if (this.$route.query.id) {
      this.fetchStoreDetail(this.$route.query.id)
    }
  }
}
</script>

<style lang="less" scoped>
</style>
